/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import shareImage from "../images/dzemo-share-image.png"
import { cutDescriptionData } from "../utils/cutDescriptionData"
import { LanguageContext } from "../contexts/languageContext"
import { getLocalizedField } from "../utils/getLocalizedField"

function SEO({ title, canonical, data }) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            defaultDescription_de: description_de
            keywords
            keywords_de
            siteUrl: url
            defaultImage: image
            twitterUsername
            author
            type
          }
        }
      }
    `
  )
  const {
    author,
    type,
    defaultImage,
    defaultTitle,
    siteUrl,
    titleTemplate,
    twitterUsername,
  } = site.siteMetadata

  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const defaultDescription =
    site.siteMetadata[
      getLocalizedField("defaultDescription", currentLanguage.label)
    ]
  const keywords =
    site.siteMetadata[getLocalizedField("keywords", currentLanguage.label)]

  const seo = {
    title: title || defaultTitle,
    description:
      cutDescriptionData(
        data?.metadescription || data?.component || data?.teaserText
      ) || defaultDescription,
    keywords: keywords,
    image: `${siteUrl}${defaultImage}`,
    url: `${siteUrl}${pathname}`,
    author: `${author}`,
    type: `${type}`,
  }

  const origin = typeof window !== "undefined" ? window.location.origin : ""
  const canonicalUrl = `${origin}${canonical}`

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      canonical ?{" "}
      <link rel="canonical" key={canonicalUrl} href={canonicalUrl} /> : []
      <meta name="keywords" content={seo.keywords} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={shareImage} />
      {/* {(article ? true : null) && <meta property="og:type" content="article" />} */}
      {seo.author && <meta name="og:author" content={seo.author} />}
      {seo.type && <meta name="og:type" content={seo.type} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      <meta name="og:image" content={shareImage} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {/* {seo.image && <meta property="og:image" content={seo.image} />} */}
      <meta property="og:site_name" content="Dzemo" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:locale:alternate" content="de_DE" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@DzemoRecruiting" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      <meta name="twitter:image" content={shareImage} />
    </Helmet>
  )
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

SEO.propTypes = {
  canonical: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  //image: PropTypes.string,
  article: PropTypes.bool,
}

export default SEO
